.calculator_container {
    width: 48%;
    min-width: 550px;
}


@media (max-width: 1200px) {
    .calculator_container {
        width: 100%;
    }
}